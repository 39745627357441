window._ = require('lodash');
window.toastr = require('toastr');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js/dist/umd/popper.js').default;

    require('bootstrap');
    require('moment');
    require('datatables.net-plugins/sorting/datetime-moment');
    require('datatables.net-bs4');
    require('bootstrap-fileinput');
    require('bootstrap-fileinput/js/locales/pt-BR');
    require('jquery-validation');
} catch (e) {}
 /*
 Solucao 1 - Falha
 try {
        window.$ = window.jQuery = require('jquery');
        window.Popper = require('popper.js/dist/umd/popper.js').default;
        require('bootstrap');
    } catch (e) {
    }
*/
/*
Solucao 2 =- Falha
import Popper from 'node_modules/popper.js/dist/umd/popper.js';
try {
    window.$ = window.jQuery = require('jquery');

    // new line #2 and #3
    window.Popper = Popper;
    require('bootstrap');
} catch (e) {}*/

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// import 'bootstrap-fileinput/js/locales/pt-BR';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
